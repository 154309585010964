import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

import { SettingsStore, SettingsShape } from "src/stores/Settings";
import { PageStore } from "src/stores/Page";
import { LatestCardsStore } from "src/stores/LatestCards";
import getLocalizedSlug from "src/utils/getLocalizedSlug";

import Layout from "src/layouts/Main";
import SEO from "src/atoms/SEO";
import Flavor, { ANIMATIONS } from "src/organisms/ProductFlavor";
import {
  getSlugForCategory,
  getSlugForSubcategory,
  getSlugForFlavor,
} from "src/utils/slugger";
import mergeSansNullOrUndefined from "src/utils/mergeSansNullOrUndefined";
import breadcrumb from "src/utils/breadcrumb";
import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import getImage from "src/utils/getImage";
import getMetaImageFields from "src/utils/getMetaImageFields";
import trackEvent from "./trackEvent";

export const ORIGINS = Object.freeze({
  sameCategory: "sameCategory",
  "sameCategory-swipeLeft": "sameCategory-swipeLeft",
  "sameCategory-swipeRight": "sameCategory-swipeRight",
  parentCategory: "parentCategory",
  subcategory: "subcategory",
  card: "card",
});

const FlavorPage = ({
  data,
  pageContext: { locale, localizedSlugs, hreflang },
  location,
  path,
}) => {
  if (!data) {
    throw new Error(`Page query failed: reason unknown`);
  }

  const settings = {
    ...data.contentfulSettings,
    locale,
    localizedSlugs,
  };

  const channelSightAssetId = data.contentfulSettings.channelSightAssetId;
  const mikmakAssetId = data.contentfulSettings.mikmakAssetId;
  const mikmakWidgetId = data.contentfulSettings.mikmakWidgetId;

  const flavor = data.contentfulProductFlavorWithCard;
  const subcategory = flavor.subcategory.find((s) => s.category);
  const category = subcategory.category[0];
  const categoryList = category.categoryList[0];

  if (!categoryList) {
    throw new Error(
      `Flavor ${flavor.slug} is not linked to a CategoryList,` +
        `has it been added to more than one category/subcategory?`
    );
  }

  const ean = flavor.eanNumber && flavor.eanNumber.toString();
  const platform = flavor.platformToUse && flavor.platformToUse;

  const cleanFlavor = {
    slug: getLocalizedSlug(
      getSlugForFlavor(flavor, subcategory, category, categoryList),
      locale
    ),
    flavorTitle: flavor.name,
    destiniAssociationCode: flavor.destiniAssociationCode,
    adimoTouchpointId: flavor.adimoTouchpointId,
    adimoWidget: flavor.adimoWidget
      ? flavor.adimoWidget === "Price First"
        ? "site-product-first"
        : "lightbox"
      : null,

    showEcom: flavor.showEComButton,
    ecomText: flavor.eComButtonText,
    eanNumber: ean,
    platformToUse: platform,
    channelSightAssetId: channelSightAssetId,
    mikmakAssetId: mikmakAssetId,
    mikmakWidgetId: mikmakWidgetId,
    retailerList:
      (flavor.retailerList &&
        flavor.retailerList.map((retailer) => ({
          ...retailer,
          logo: getImage(retailer.logo, PICTURE_TYPES.fixed),
        }))) ||
      [],
    flavorDescription: flavor.introduction,
    mainImage: getImage(flavor.image, PICTURE_TYPES.fluid),
    nutritionalHighlights:
      (flavor.majorNutritionalFact &&
        flavor.majorNutritionalFact.map(
          ({
            name,
            value,
            isEnergyNutritionalFact,
            secondaryValue,
            colorCode,
            percentage,
          }) => ({
            label: name,
            value,
            isEnergyNutritionalFact,
            secondaryValue,
            colorCode,
            percentage,
          })
        )) ||
      [],
    beforeNutritionalHighlightsText:
      flavor?.majorNutritionalFactsSupplement?.beforeMajorNutritionalFactsText,
    afterNutritionalHighlightsText:
      flavor?.majorNutritionalFactsSupplement?.afterMajorNutritionalFactsText,
    healthStarRating: flavor?.majorNutritionalFactsSupplement?.healthStarRating,
    afterHealthStarRatingText:
      flavor?.majorNutritionalFactsSupplement?.afterHealthStarRatingText,
    nutritionalDetails: {
      title: flavor.nutritionalFactsTitle,
      rows: flavor.nutritionalFactsLabels.map((name, key) => ({
        name,
        per100: (flavor.nutritionalFactsPer100 || [])[key],
        perServing: (flavor.nutritionalFactsPerServing || [])[key],
        rda: (flavor.nutritionalFactsRDA || [])[key],
      })),
    },
    nutritionalDetails2: {
      title: flavor.nutritionalFactsTitle2,
      rows: (flavor.nutritionalFactsLabels2 || []).map((name, key) => ({
        name,
        per100: (flavor.nutritionalFactsPer1002 || [])[key],
        perServing: (flavor.nutritionalFactsPerServing2 || [])[key],
        rda: (flavor.nutritionalFactsRda2 || [])[key],
      })),
    },
    nutritionalDetails3: {
      title: flavor.nutritionalFactsTitle3,
      rows: (flavor.nutritionalFactsLabels3 || []).map((name, key) => ({
        name,
        per100: (flavor.nutritionalFactsPer1003 || [])[key],
        perServing: (flavor.nutritionalFactsPerServing3 || [])[key],
        rda: (flavor.nutritionalFactsRda3 || [])[key],
      })),
    },
    nutritionalDetails4: {
      title: flavor.nutritionalFactsTitle4,
      rows: (flavor.nutritionalFactsLabels4 || []).map((name, key) => ({
        name,
        per100: (flavor.nutritionalFactsPer1004 || [])[key],
        perServing: (flavor.nutritionalFactsPerServing4 || [])[key],
        rda: (flavor.nutritionalFactsRda4 || [])[key],
      })),
    },
    nutritionalSmall: flavor.nutritionalFactsLegalNotice,
    blurbTitle: flavor.productDescriptionTitle,
    blurbDescription: flavor.productDescription,
    blurbDetails: flavor.productDetailsLabels.map((label, key) => [
      label,
      flavor.productDetailsValues[key],
    ]),
    ingredientHighlights:
      flavor.ingredients &&
      flavor.ingredients.map(({ title, media }) => ({
        label: title,
        image: getImage(media, PICTURE_TYPES.fixed),
      })),
    ingredientDescription: flavor.ingredientsDescription,
    faq: flavor.faq,
    relatedCards: (flavor.relatedCards || {}).cards || [],
    categoryPush: flavor.categoryPush && {
      ...flavor.categoryPush,
      image: flavor.categoryPush.image
        ? getImage(flavor.categoryPush.image, PICTURE_TYPES.fluid)
        : undefined,
      category: {
        ...flavor.categoryPush.category,
        image: getImage(
          flavor.categoryPush.category.listViewMedia,
          PICTURE_TYPES.fluid
        ),
        slug: getLocalizedSlug(
          getSlugForCategory(flavor.categoryPush.category),
          locale
        ),
      },
    },
    subcategory: {
      ...subcategory,
      name: subcategory.title,
      slug: getLocalizedSlug(getSlugForSubcategory(subcategory), locale),
      flavors: subcategory.flavors.map((thisFlavor) => ({
        image: getImage(thisFlavor.image, PICTURE_TYPES.fluid),
        slug: getLocalizedSlug(
          getSlugForFlavor(thisFlavor, subcategory, category, categoryList),
          locale
        ),
        label: thisFlavor.name,
        color: thisFlavor.color,
        active:
          getSlugForFlavor(flavor, subcategory, category, categoryList) ===
          getSlugForFlavor(thisFlavor, subcategory, category, categoryList),
      })),
      category: {
        ...category,
        id: category.id,
        image: getImage(category.listViewMedia, PICTURE_TYPES.fluid),
        categoryList: {
          slug: categoryList.slug,
        },
        subcategories: category.subcategories.map(
          ({ title, ...thisSubcategory }) => ({
            name: title,
            slug: getLocalizedSlug(
              getSlugForSubcategory(thisSubcategory, category, categoryList),
              locale
            ),
            active:
              getSlugForSubcategory(subcategory, category, categoryList) ===
              getSlugForSubcategory(thisSubcategory, category, categoryList),
          })
        ),
      },
    },
  };

  const from = (location.state || {}).from;
  const length = ean && ean.length;
  const gtin = length === 8 || length === 13 ? `gtin${length}` : null;

  const jsonld = [
    breadcrumb([
      {
        name: "Category list page",
        url: `${locale.currentRegion.url}${categoryList.slug}`,
      },
      {
        name: cleanFlavor.subcategory.category.title,
        url: `${locale.currentRegion.url}${categoryList.slug}${category.slug}`,
      },
      {
        name: cleanFlavor.subcategory.name,
        url: `${locale.currentRegion.url}${categoryList.slug}${category.slug}${subcategory.slug}`,
      },
      {
        name: cleanFlavor.flavorTitle,
        url: `${locale.currentRegion.url}${cleanFlavor.slug}`,
      },
    ]),
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: cleanFlavor.flavorTitle,
      image: cleanFlavor.mainImage.src,
      description: documentToPlainTextString(
        JSON.parse(cleanFlavor.flavorDescription.raw)
      ),
      sku: flavor.sku,
      brand: {
        "@type": "Thing",
        name: "Activia",
      },
      ...(gtin ? { [gtin]: ean } : {}),
    },
  ];

  const flavorMetadata = {
    ...flavor.seo,
    image:
      flavor.seo.image &&
      getMetaImageFields(flavor.seo.image, PICTURE_TYPES.fixed),
  };

  const meta = mergeSansNullOrUndefined(
    {
      title: cleanFlavor.flavorTitle,
      description: documentToPlainTextString(
        JSON.parse(cleanFlavor.flavorDescription.raw)
      ),
      image: {
        width: cleanFlavor.mainImage.width,
        height: cleanFlavor.mainImage.height,
        src: cleanFlavor.mainImage.src,
      },
    },
    flavorMetadata
  );

  useEffect(() => {
    window.tc_vars = {
      country: locale.currentRegion.region,
      environment: process.env.GATSBY_TRACKING_ENV,
      page_category: "Product Flavor",
      page_type: "Flavor Page",
      page_name: subcategory.title,
      page_subcategory: category.title,
      page: path,
      product_category: category.title,
      product_name: cleanFlavor.flavorTitle,
      work_env: process.env.GATSBY_TRACKING_ENV,
      brand: "Activia",
    };
    window.tC.container.reload({ events: { page_view: [{}, {}] } });

    trackEvent();
    //console.log('window.tc_vars flavor page',window.tc_vars);
  }, []);

  return (
    <SettingsStore value={settings}>
      <SEO
        path={path}
        meta={meta}
        jsonld={jsonld}
        hreflang={hreflang}
        localizedSlugs={localizedSlugs}
      />
      <LatestCardsStore {...data}>
        <PageStore>
          <Layout header={false} locale={locale}>
            <Flavor
              flavor={cleanFlavor}
              animation={
                {
                  [ORIGINS["sameCategory"]]: ANIMATIONS.staticright,
                  [ORIGINS["parentCategory"]]: ANIMATIONS.staticright,
                  [ORIGINS["subcategory"]]: ANIMATIONS.staticup,
                  [ORIGINS["sameCategory-swipeLeft"]]: ANIMATIONS.staticleft,
                  [ORIGINS["sameCategory-swipeRight"]]: ANIMATIONS.staticright,
                }[from]
              }
            />
          </Layout>
        </PageStore>
      </LatestCardsStore>
    </SettingsStore>
  );
};

FlavorPage.propTypes = {
  data: PropTypes.shape({
    contentfulSettings: PropTypes.shape(SettingsShape).isRequired,
    contentfulProductFlavorWithCard: PropTypes.object.isRequired,
  }),
  pageContext: PropTypes.shape({
    locale: PropTypes.object.isRequired,
    localizedSlugs: PropTypes.instanceOf(Array),
    hreflang: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.oneOf(Object.values(ORIGINS)),
    }),
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export const flavorPageQuery = graphql`
  query getFlavorPage($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...SettingsFields
    }

    contentfulProductFlavorWithCard(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...Flavor
    }

    latestEditorialPhotography: allContentfulEditorialPhotographyWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialPhotographyCard
        updatedAt
      }
    }

    latestEditorialIllustration: allContentfulEditorialIllustrationWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialIllustrationCard
        updatedAt
      }
    }

    latestInfluencer: allContentfulInfluencerWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...InfluencerCard
        updatedAt
      }
    }

    latestRecipe: allContentfulRecipeWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...RecipeCard
        updatedAt
      }
    }

    latestFlavor: allContentfulProductFlavorWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...FlavorCard
        updatedAt
      }
    }
  }
`;

export default FlavorPage;
