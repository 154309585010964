import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useSwipeable } from "react-swipeable";
import { Visible } from "react-awesome-styled-grid";

import { colors, icons, spacing, lineHeight } from "src/styles/variables";
import Settings from "src/stores/Settings";
import GridPadding from "src/atoms/GridPadding";
import {
  HeaderM,
  HeaderS,
  SubHeaderM,
  IntroText,
  Detail,
  Body,
} from "src/atoms/Typography";
import Plus from "src/atoms/Vectors/Links/Plus";
import Minus from "src/atoms/Vectors/Links/Minus";
import Picture, { FITS } from "src/atoms/Picture";
import Dots from "src/atoms/Dots";
import Accordion from "src/atoms/Accordion";
import NumericFactList from "src/molecules/NumericFactList";
import NutritionalTable from "src/molecules/NutritionalTable";
import NutritionalTableCarousel from "src/organisms/NitritionalTableCarousel";
import DetailList from "src/atoms/DetailList";
import IngredientList from "src/molecules/IngredientList";
import RichText from "src/atoms/RichText";
import CardCarousel from "src/molecules/CardCarousel";
import FloatingButton from "src/molecules/FloatingButton";
import ArrowLeft from "src/atoms/Vectors/Arrows/ArrowLeft";
import ArrowRight from "src/atoms/Vectors/Arrows/ArrowRight";
import QuestionAnswerList from "src/organisms/QuestionAnswerList";
// import { trackNutritionalInteraction, trackFAQ } from "./trackEvents";
import RetailerAccordion from "src/molecules/RetailerAccordion";
import EcommerceButton from "./ProductEcommerceButton";

const FlavorDetails = ({
  className,
  onSelectFlavor,
  slug,
  mainImage,
  flavors,
  flavorTitle,
  destiniAssociationCode,
  adimoTouchpointId,
  adimoWidget,
  showEcom,
  ecomText,
  eanNumber,
  platformToUse,
  channelSightAssetId,
  mikmakAssetId,
  mikmakWidgetId,
  retailerList,
  flavorDescription,
  nutritionalHighlights,
  beforeNutritionalHighlightsText,
  afterNutritionalHighlightsText,
  healthStarRating,
  afterHealthStarRatingText,
  nutritionalDetails,
  nutritionalDetails2,
  nutritionalDetails3,
  nutritionalDetails4,
  nutritionalSmall,
  blurbTitle,
  blurbDescription,
  blurbDetails,
  ingredientHighlights,
  ingredientDescription,
  relatedCards,
  faq,
  showDestiniWidget,
}) => {
  const { useAccessibilityColor, useTrafficLightNutritionFacts } = useContext(
    Settings
  );
  const currentFlavorIdx = flavors.findIndex((f) => f.active);
  const leftIdx = currentFlavorIdx > 0 ? currentFlavorIdx - 1 : null;
  const rightIdx =
    currentFlavorIdx < flavors.length - 1 ? currentFlavorIdx + 1 : null;
  const [reload, setReload] = useState(false);
  const [healthStarRatingImg, setHealthStarRatingImg] = useState(null);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => {
      if (rightIdx !== null) {
        onSelectFlavor(flavors[rightIdx], "swipeLeft");
      }
    },
    onSwipedRight: (e) => {
      if (leftIdx !== null) {
        onSelectFlavor(flavors[leftIdx], "swipeRight");
      }
    },
  });

  useEffect(() => {
    setReload(true);
  }, [className]);

  useEffect(() => {
    setReload(false);
  }, [mainImage]);

  useEffect(() => {
    if (healthStarRating) {
      import(
        `src/images/hsr/${String(healthStarRating.toFixed(1)).replace(
          ".",
          "-"
        )}_HSR_Icon.png`
      ).then((img) => setHealthStarRatingImg(img.default));
    }
  }, []);

  const nutritionalTables = [
    nutritionalDetails,
    nutritionalDetails2,
    nutritionalDetails3,
    nutritionalDetails4,
  ].filter((table) => table.rows.length);

  return (
    <Settings.Consumer>
      {({ locale, translations }) => (
        <Outer className={className}>
          <StyledGridPadding reload={reload}>
            <ImageContainer {...swipeHandlers}>
              <Visible md lg xl>
                {leftIdx !== null && (
                  <LeftContainer>
                    <FloatingButton translucid>
                      <ArrowLeft
                        stroke={colors.activiaGreen}
                        size={icons.m}
                        onClick={() =>
                          onSelectFlavor(flavors[leftIdx], "swipeRight")
                        }
                      />
                    </FloatingButton>
                  </LeftContainer>
                )}
              </Visible>
              <MainImage fit={FITS.contain} small={mainImage} />
              <Visible md lg xl>
                {rightIdx !== null && (
                  <RightContainer>
                    <FloatingButton translucid>
                      <ArrowRight
                        stroke={colors.activiaGreen}
                        size={icons.m}
                        onClick={() =>
                          onSelectFlavor(flavors[rightIdx], "swipeLeft")
                        }
                      />
                    </FloatingButton>
                  </RightContainer>
                )}
              </Visible>
            </ImageContainer>
            {flavors.length > 1 && (
              <DotsStyled
                items={flavors.map(
                  ({ label, color, slug: thisSlug, active }) => ({
                    label,
                    color,
                    slug: thisSlug,
                    active,
                  })
                )}
                onSelect={onSelectFlavor}
                large
                showTitleOnHover
              />
            )}
            <EcommerceButton
              showEcom={showEcom}
              destiniAssociationCode={destiniAssociationCode}
              retailerList={retailerList}
              flavorTitle={flavorTitle}
              ecomText={ecomText}
              eanNumber={eanNumber}
              platformToUse={platformToUse}
              showDestiniWidget={showDestiniWidget}
              adimoTouchpointId={adimoTouchpointId}
              adimoWidget={adimoWidget}
              channelSightAssetId={channelSightAssetId}
              mikmakAssetId={mikmakAssetId}
              mikmakWidgetId={mikmakWidgetId}
            />
            {flavorTitle.length > 20 ? (
              <SmallTitle as="h1">{flavorTitle}</SmallTitle>
            ) : (
              <Title as="h1">{flavorTitle}</Title>
            )}

            <IntroStyled as="h2">
              <RichText doc={flavorDescription} renderNode={{}} />
            </IntroStyled>
          </StyledGridPadding>
          {/* Retailer list isn't handled by EcommerceButton as it's embeded in a different part of the page */}
          <ContentContainer reload={reload}>
            {!!showEcom && ecomText && retailerList.length ? (
              <GridPadding>
                <RetailerAccordion
                  buttonText={ecomText}
                  retailers={retailerList}
                />
              </GridPadding>
            ) : null}

            {!useTrafficLightNutritionFacts && (
              <>
                {!!beforeNutritionalHighlightsText && (
                  <StyledGridPadding>
                    <NutritionalFactSupplementText
                      doc={beforeNutritionalHighlightsText}
                      useTrafficLight={useTrafficLightNutritionFacts}
                      renderNode={{}}
                    />
                  </StyledGridPadding>
                )}
                {!!nutritionalHighlights.length && (
                  <NumericFactListStyled
                    useTrafficLight={useTrafficLightNutritionFacts}
                    facts={nutritionalHighlights}
                    align="left"
                  />
                )}
                {!!afterNutritionalHighlightsText && (
                  <StyledGridPadding>
                    <NutritionalFactSupplementText
                      doc={afterNutritionalHighlightsText}
                      useTrafficLight={useTrafficLightNutritionFacts}
                      renderNode={{}}
                    />
                  </StyledGridPadding>
                )}
              </>
            )}
            <GridPadding>
              <NutritionalAccordionStyled
                hiddenLabel={
                  <>
                    <AlignedPlus
                      size={icons.xs}
                      stroke={
                        useAccessibilityColor
                          ? colors.accessibilityGreen
                          : colors.activiaGreen
                      }
                      fill="none"
                    />{" "}
                    {translations.expandNutritionalFacts}
                  </>
                }
                visibleLabel={
                  <>
                    <AlignedMinus
                      size={icons.xs}
                      stroke={
                        useAccessibilityColor
                          ? colors.accessibilityGreen
                          : colors.activiaGreen
                      }
                      fill="none"
                    />{" "}
                    {translations.collapseNutritionalFacts}
                  </>
                }
                // trackInteraction={() => {
                //   trackNutritionalInteraction(flavorTitle);
                // }}
              >
                {!!useTrafficLightNutritionFacts && (
                  <TrafficLightFactsContainer>
                    {!!beforeNutritionalHighlightsText && (
                      <StyledGridPadding>
                        <NutritionalFactSupplementText
                          doc={beforeNutritionalHighlightsText}
                          useTrafficLight={useTrafficLightNutritionFacts}
                          renderNode={{}}
                        />
                      </StyledGridPadding>
                    )}
                    {!!nutritionalHighlights.length && (
                      <NumericFactListStyled
                        useTrafficLight={useTrafficLightNutritionFacts}
                        facts={nutritionalHighlights}
                        align="left"
                      />
                    )}
                    {!!afterNutritionalHighlightsText && (
                      <StyledGridPadding>
                        <NutritionalFactSupplementText
                          doc={afterNutritionalHighlightsText}
                          useTrafficLight={useTrafficLightNutritionFacts}
                          renderNode={{}}
                        />
                      </StyledGridPadding>
                    )}
                    {(!!healthStarRating || !!afterHealthStarRatingText) && (
                      <HealthStarRatingWrapper>
                        <StyledGridPadding>
                          {!!healthStarRating && (
                            <HealthStarRatingContainer
                              useTrafficLight={useTrafficLightNutritionFacts}
                            >
                              <HealthStarRatingImage
                                src={healthStarRatingImg}
                              />
                            </HealthStarRatingContainer>
                          )}
                          {!!afterHealthStarRatingText && (
                            <NutritionalFactSupplementText
                              doc={afterHealthStarRatingText}
                              useTrafficLight={useTrafficLightNutritionFacts}
                              renderNode={{}}
                            />
                          )}
                        </StyledGridPadding>
                      </HealthStarRatingWrapper>
                    )}
                  </TrafficLightFactsContainer>
                )}
                <NutritionalTableCarousel tables={nutritionalTables} />
                <NutritionalSmall as="small">
                  {nutritionalSmall}
                </NutritionalSmall>
              </NutritionalAccordionStyled>
              <Subtitle as="h3">{blurbTitle}</Subtitle>
              <ParagraphStyled as="div">
                <RichText doc={blurbDescription} renderNode={{}} />
              </ParagraphStyled>
              <DetailListStyled details={blurbDetails} />
              <Subtitle as="h3">{translations.whatsInsideProduct}</Subtitle>
            </GridPadding>
            {ingredientHighlights && (
              <IngredientListStyled
                ingredients={ingredientHighlights}
                align="left"
              />
            )}
            <GridPadding>
              <Subtitle as="h3">{translations.allIngredientsProduct}</Subtitle>
              <ParagraphStyled as="div">
                <RichText doc={ingredientDescription} renderNode={{}} />
              </ParagraphStyled>
              {faq && (
                <QuestionAnswerList
                  {...faq}
                  collapsible
                  // trackInteraction={() => {
                  //   trackFAQ();
                  // }}
                />
              )}
              {(relatedCards || []).length > 0 && (
                <CardCarousel cards={relatedCards} />
              )}
            </GridPadding>
            {flavors.length > 1 && (
              <Preload aria-hidden="true">
                {flavors.map((f) => (
                  <Picture small={f.image} fit={FITS.contain} key={f.slug} />
                ))}
              </Preload>
            )}
          </ContentContainer>
        </Outer>
      )}
    </Settings.Consumer>
  );
};

FlavorDetails.propTypes = {
  className: PropTypes.string,
  onSelectFlavor: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  mainImage: PropTypes.object.isRequired,
  flavors: Dots.propTypes.items.isRequired,
  flavorTitle: PropTypes.string.isRequired,
  destiniAssociationCode: PropTypes.string,
  adimoTouchpointId: PropTypes.string,
  adimoWidget: PropTypes.string,
  showEcom: PropTypes.bool,
  ecomText: PropTypes.string,
  eanNumber: PropTypes.string,
  platformToUse: PropTypes.string,
  channelSightAssetId: PropTypes.string,
  mikmakAssetId: PropTypes.string,
  mikmakWidgetId: PropTypes.string,
  retailerList: RetailerAccordion.propTypes.retailers,
  flavorDescription: RichText.propTypes.doc, // TODO: make this rich text
  nutritionalHighlights: NumericFactList.propTypes.facts.isRequired,
  beforeNutritionalHighlightsText: RichText.propTypes.doc,
  afterNutritionalHighlightsText: RichText.propTypes.doc,
  healthStarRating: PropTypes.number,
  afterHealthStarRatingText: RichText.propTypes.doc,
  nutritionalDetails: PropTypes.shape(NutritionalTable.propTypes).isRequired,
  nutritionalDetails2: PropTypes.shape(NutritionalTable.propTypes),
  nutritionalDetails3: PropTypes.shape(NutritionalTable.propTypes),
  nutritionalDetails4: PropTypes.shape(NutritionalTable.propTypes),
  nutritionalSmall: PropTypes.string,
  blurbTitle: PropTypes.string.isRequired,
  blurbDescription: RichText.propTypes.doc,
  blurbDetails: DetailList.propTypes.details.isRequired,
  ingredientHighlights: IngredientList.propTypes.ingredients,
  ingredientDescription: RichText.propTypes.doc,
  relatedCards: PropTypes.arrayOf(PropTypes.object),
  faq: PropTypes.object,
  showDestiniWidget: PropTypes.func,
};

const reloadAnimation = css`
  opacity: ${({ reload }) => (reload ? 0 : 1)};
  transition: opacity 150ms cubic-bezier(0, 0, 0.12, 1);
`;

const Outer = styled.div`
  overflow: hidden;
`;

const MainImage = styled(Picture)`
  margin: ${spacing.stack.lg};
`;

const DotsStyled = styled(Dots)`
  justify-content: center;
  margin: ${spacing.stack.xl};
`;

const Title = styled(HeaderM)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.md};
`;

const SmallTitle = styled(HeaderS)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.md};
`;

const IntroStyled = styled(IntroText)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.sm};
`;

const TrafficLightFactsContainer = styled.div`
  margin-top: ${spacing.default.md};
`;

const NutritionalFactSupplementText = styled(RichText)`
  text-align: ${({ useTrafficLight }) => (useTrafficLight ? "center" : "left")};
  margin: ${spacing.stack.sm};
  line-height: ${lineHeight(16, 19)};

  p,
  a,
  span {
    color: ${colors.darkGrey};
  }

  a {
    white-space: nowrap;
  }

  p {
    margin: ${spacing.stack.xs};
  }
`;

const NumericFactListStyled = styled(NumericFactList)`
  margin: ${({ useTrafficLight }) =>
    useTrafficLight ? spacing.stack.sm : spacing.stack.lg};
  padding: 0 ${spacing.default.md};
`;

const HealthStarRatingWrapper = styled.div`
  margin: ${spacing.default.lg} 0;
`;

const HealthStarRatingContainer = styled.div`
  display: flex;
  justify-content: ${({ useTrafficLight }) =>
    useTrafficLight ? "center" : "flex-start"};
`;

const HealthStarRatingImage = styled.img`
  margin: ${spacing.stack.sm};
  width: 125px;
`;

const NutritionalAccordionStyled = styled(Accordion)`
  margin: ${spacing.stack.xl};
`;

const NutritionalSmall = styled(Detail)`
  color: ${colors.darkGrey};
`;

const Subtitle = styled(SubHeaderM)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.md};
`;

const ParagraphStyled = styled(Body)`
  color: ${colors.darkGrey};
  margin: ${spacing.stack.lg};
`;

const AlignedPlus = styled(Plus)`
  transform: translateY(1px);
`;

const AlignedMinus = styled(Minus)`
  transform: translateY(1px);
`;

const DetailListStyled = styled(DetailList)`
  margin: ${spacing.stack.xl};
`;

const IngredientListStyled = styled(IngredientList)`
  margin: ${spacing.stack.xl};
  padding: 0 ${spacing.default.md};
`;

const ImageContainer = styled.div`
  ${reloadAnimation}
  position: relative;
`;

const ContentContainer = styled.div`
  ${reloadAnimation}
`;

const StyledGridPadding = styled(GridPadding)`
  ${reloadAnimation}
`;

const ArrowContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s cubic-bezier(0.58, 0, 0.56, 1);

  &:hover {
    opacity: 1;
  }
`;

const LeftContainer = styled(ArrowContainer)`
  left: -10px;
`;
const RightContainer = styled(ArrowContainer)`
  right: -10px;
`;

const Preload = styled.div`
  width: 1px;
  height: 1px;
  position: absolute;
  left: 1000000px;
  opacity: 0.01;
`;

export default FlavorDetails;
